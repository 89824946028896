import React from "react";
import sensors from "./sensors-list";
import get from "lodash/get";

const getType = type => {
	let res = "Не определен";
	type === 1 && (res = "Цифровой");
	type === 2 && (res = "Аналоговый");
	type === 3 && (res = "Частотный");
	return res;
};

export const SensorsList = props => {
	const filteredSensors = props.system
		? sensors.filter(s => s.is_system)
		: sensors.filter(s => !s.is_system);

	return (
		<div style={{ marginBottom: "1.5rem" }}>
			<table style={{ width: "100%" }}>
				<thead>
					<tr>
						<th>Название</th>
						<th>Тип</th>
						<th>Тэг</th>
						{props.system && <th>Значения</th>}
					</tr>
				</thead>
				<tbody>
					{filteredSensors.map(d => (
						<tr key={d.name}>
							<td>{d.name}</td>
							<td>{getType(d.type)}</td>
							<td>{d.keys.join(", ")}</td>
							{props.system && (
								<td>
									{d.type === 1
										? get(d, "unit_d", []).join(", ")
										: get(d, "unit_a", []).join(", ")}
								</td>
							)}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
