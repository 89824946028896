export default [
	{
		name: "Напряжение батареи",
		type: 2,
		keys: ["bat_v"],
		is_system: true,
		unit_a: ["мВ"],
		status: false,
		notify: 0,
		visible: false,
		visible_graph: true
	},
	{
		name: "Процент батареи",
		type: 2,
		keys: ["bat_p"],
		is_system: true,
		unit_a: ["%"],
		status: true,
		notify: 0,
		visible: true,
		visible_graph: true
	},
	{
		name: "Напряжение сети",
		type: 2,
		keys: ["sup_v"],
		is_system: true,
		unit_a: ["мВ"],
		status: false,
		notify: 0,
		visible: false,
		visible_graph: true
	},
	{
		name: "Статус зарядки",
		type: 1,
		keys: ["is_chr"],
		is_system: true,
		unit_d: ["Выкл", "Вкл"],
		label: [1, 4],
		status: true,
		notify: 0,
		visible: true,
		visible_graph: true
	},
	{
		name: "Температура в устройстве",
		type: 2,
		keys: ["dev_temp"],
		is_system: true,
		unit_a: ["℃"],
		status: false,
		notify: 0,
		visible: true,
		visible_graph: true
	},
	{
		name: "Обороты в двигателе",
		type: 2,
		keys: ["rpm"],
		is_system: true,
		unit_a: ["rpm"],
		status: false,
		notify: 0,
		visible: false,
		visible_graph: true
	},
	{
		name: "Температура в двигателе",
		type: 2,
		keys: ["coolant_temp"],
		is_system: true,
		unit_a: ["℃"],
		status: false,
		notify: 0,
		visible: false,
		visible_graph: true
	},
	{
		name: "Пробег по одометру",
		type: 2,
		keys: ["mileage_can"],
		is_system: true,
		unit_a: ["м"],
		status: true,
		notify: 0,
		visible: false,
		visible_graph: true
	},
	{
		name: "Пробег по gps",
		type: 2,
		keys: ["mileage_gps"],
		is_system: true,
		unit_a: ["м"],
		status: false,
		notify: 0,
		visible: false,
		visible_graph: true
	},
	{
		name: "Общий пробег по gps",
		type: 2,
		keys: ["mileage_gps_total"],
		is_system: true,
		unit_a: ["м"],
		status: false,
		notify: 0,
		visible: true,
		visible_graph: false
	},
	{
		name: "Топливо за всё время",
		type: 2,
		keys: ["can_fuel_from_start"],
		is_system: true,
		unit_a: ["л"],
		status: true,
		notify: 0,
		visible: false,
		visible_graph: false
	},
	{
		name: "Точность",
		type: 2,
		keys: ["accuracy"],
		is_system: true,
		unit_a: ["м"],
		status: false,
		notify: 0,
		visible: false,
		visible_graph: true
	},
	{
		name: "Высота",
		type: 2,
		keys: ["altitude"],
		is_system: true,
		unit_a: ["м"],
		status: false,
		notify: 0,
		visible: false,
		visible_graph: true
	},
	{
		name: "Горизонтальная точность",
		type: 2,
		keys: ["hdop"],
		is_system: true,
		unit_a: ["м"],
		status: false,
		notify: 0,
		visible: false,
		visible_graph: true
	},
	{
		name: "PDOP",
		type: 2,
		keys: ["pdop"],
		is_system: true,
		unit_a: ["м"],
		status: false,
		notify: 0,
		visible: false,
		visible_graph: true
	},
	{
		name: "Количество спутников",
		type: 2,
		keys: ["sat_c"],
		is_system: true,
		unit_a: ["шт."],
		status: false,
		notify: 0,
		visible: false,
		visible_graph: true
	},
	{
		name: "Уровень GSM сигнала",
		type: 2,
		keys: ["gsm_signal"],
		is_system: true,
		unit_a: ["%"],
		status: false,
		notify: 0,
		visible: false,
		visible_graph: true,
		formula: "(gsm_signal*100/5)"
	},
	{
		name: "Угол",
		type: 2,
		keys: ["angle"],
		is_system: true,
		unit_a: ["°"],
		status: false,
		notify: 0,
		visible: false,
		visible_graph: true
	},
	{
		name: "Зажигание",
		type: 1,
		keys: ["ign"],
		is_system: true,
		unit_d: ["Выкл", "Вкл"],
		label: [2, 2],
		status: true,
		notify: 0,
		visible: false,
		visible_graph: true
	},
	{
		name: "Уровень топлива",
		type: 2,
		keys: ["fuel_level"],
		is_system: true,
		unit_a: ["л"],
		status: true,
		notify: 0,
		visible: true,
		visible_graph: true
	},
	{
		name: "Нагрузка на ось",
		type: 2,
		keys: ["axle_load"],
		is_system: true,
		unit_a: ["кг"],
		status: true,
		notify: 0,
		visible: false,
		visible_graph: true
	},
	{
		name: "Резкое торможение",
		type: 2,
		keys: ["braking_accel"],
		is_system: true,
		unit_a: ["м/с"],
		status: true,
		notify: 0,
		visible: false,
		visible_graph: false
	},
	{
		name: "Резкое ускорение",
		type: 2,
		keys: ["course_accel"],
		is_system: true,
		unit_a: ["м/с"],
		status: true,
		notify: 0,
		visible: false,
		visible_graph: false
	},
	{
		name: "Резкий поворот",
		type: 2,
		keys: ["turn_accel"],
		is_system: true,
		unit_a: ["м/с"],
		status: true,
		notify: 0,
		visible: false,
		visible_graph: false
	},
	{
		name: "Удар на кочках",
		type: 2,
		keys: ["vertical_accel"],
		is_system: true,
		unit_a: ["м/с"],
		status: true,
		notify: 0,
		visible: false,
		visible_graph: false
	},
	{
		name: "Статус батареи",
		type: 1,
		keys: ["bat_v_err"],
		is_system: true,
		unit_d: ["заряжено", "разряжено", "не исправна"],
		label: [1, 4, 4],
		status: true,
		notify: 3,
		visible: false,
		visible_graph: true
	},
	{
		name: "Статус питания от сети",
		type: 1,
		keys: ["sup_v_err"],
		is_system: true,
		unit_d: [
			"подключено",
			"отключено",
			"высокое напряжение",
			"низкое напряжение"
		],
		label: [1, 4, 4, 4],
		status: true,
		notify: 3,
		visible: false,
		visible_graph: true
	},
	{
		name: "SIM карта",
		type: 1,
		keys: ["sim_err"],
		is_system: true,
		unit_d: ["обнаружена", "не обнаружена"],
		label: [1, 4],
		status: true,
		notify: 0,
		visible: false,
		visible_graph: true
	},
	{
		name: "GPS антенна",
		type: 1,
		keys: ["gps_ant_err"],
		is_system: true,
		unit_d: ["обнаружена", "не обнаружена"],
		label: [1, 4],
		status: true,
		notify: 3,
		visible: false,
		visible_graph: true
	},
	{
		name: "GPS антенна замыкание",
		type: 1,
		keys: ["gps_ant_circuit"],
		is_system: true,
		unit_d: ["в норме", "короткое замыкание"],
		label: [1, 4],
		status: true,
		notify: 3,
		visible: false,
		visible_graph: true
	},
	{
		name: "Сигнализация",
		type: 1,
		keys: ["signalling"],
		is_system: true,
		unit_d: ["в норме", "сработала"],
		label: [1, 4],
		status: true,
		notify: 3,
		visible: false,
		visible_graph: true
	},
	{
		name: "Тревожная кнопка",
		type: 1,
		keys: ["anxiety"],
		is_system: true,
		unit_d: ["в норме", "сработала"],
		label: [1, 4],
		status: true,
		notify: 3,
		visible: false,
		visible_graph: true
	},
	{
		name: "Вскрытие трекера",
		type: 1,
		keys: ["unbox"],
		is_system: true,
		unit_d: ["в норме", "обнаружено"],
		label: [1, 4],
		status: true,
		notify: 3,
		visible: false,
		visible_graph: true
	},
	{
		name: "Перегрев двигателя",
		type: 1,
		keys: ["engine_hot"],
		is_system: true,
		unit_d: ["не обнаружено", "зафиксирован"],
		label: [1, 4],
		status: true,
		notify: 3,
		visible: false,
		visible_graph: true
	},
	{
		name: "Движение на холодном двигателе",
		type: 1,
		keys: ["engine_cold"],
		is_system: true,
		unit_d: ["не обнаружено", "зафиксирован"],
		label: [1, 4],
		status: true,
		notify: 3,
		visible: false,
		visible_graph: true
	},
	{
		name: "Дискретный вход",
		type: 1,
		keys: ["in_d[n]"],
		is_system: false
	},
	{
		name: "Аналоговый вход",
		type: 2,
		keys: ["in_a[n]"],
		is_system: false
	},
	{
		name: "Дискретный выход",
		type: 1,
		keys: ["out_d[n]"],
		is_system: false
	},
	{
		name: "Аналоговый выход",
		type: 2,
		keys: ["out_a[n]"],
		is_system: false
	},
	{
		name: "RS485",
		type: 2,
		keys: ["rs485_[n]"],
		is_system: false
	},
	{
		name: "RS485 Temp",
		type: 2,
		keys: ["rs485_[n]_t"],
		is_system: false
	},
	{
		name: "RS232",
		type: 2,
		keys: ["rs485_[n]"],
		is_system: false
	},
	{
		name: "CAN 8 bit",
		type: 2,
		keys: ["can8bitr[0]"],
		is_system: false
	},
	{
		name: "CAN 16 bit",
		type: 2,
		keys: ["can16bitr[0]"],
		is_system: false
	},
	{
		name: "CAN 32 bit",
		type: 2,
		keys: ["can32bitr[0]"],
		is_system: false
	}
];
