// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-general-index-mdx": () => import("./../../../../src/general/index.mdx" /* webpackChunkName: "component---src-general-index-mdx" */),
  "component---src-index-mdx": () => import("./../../../../src/index.mdx" /* webpackChunkName: "component---src-index-mdx" */),
  "component---src-monitoring-features-mdx": () => import("./../../../../src/monitoring/features.mdx" /* webpackChunkName: "component---src-monitoring-features-mdx" */),
  "component---src-monitoring-index-mdx": () => import("./../../../../src/monitoring/index.mdx" /* webpackChunkName: "component---src-monitoring-index-mdx" */),
  "component---src-monitoring-jps-mdx": () => import("./../../../../src/monitoring/jps.mdx" /* webpackChunkName: "component---src-monitoring-jps-mdx" */),
  "component---src-monitoring-mobileapp-mdx": () => import("./../../../../src/monitoring/mobileapp.mdx" /* webpackChunkName: "component---src-monitoring-mobileapp-mdx" */),
  "component---src-monitoring-sensors-list-mdx": () => import("./../../../../src/monitoring/sensors-list.mdx" /* webpackChunkName: "component---src-monitoring-sensors-list-mdx" */),
  "component---src-monitoring-sensors-mdx": () => import("./../../../../src/monitoring/sensors.mdx" /* webpackChunkName: "component---src-monitoring-sensors-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

