import React from "react";
import devices from "./devices-list";

export const Devices = () => {
	return (
		<div style={{ marginBottom: "1.5rem" }}>
			<table style={{ width: "100%" }}>
				<thead>
					<tr>
						<th>Название</th>
						<th>Поддерж. модели</th>
						<th>Протокол</th>
						<th>Порт</th>
					</tr>
				</thead>
				<tbody>
					{devices.map(d => (
						<tr key={d.name}>
							<td>{d.name}</td>
							<td>{d.support_models}</td>
							<td>{d.protocol_completed}</td>
							<td>{d.port}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
