export default [
	{
		name: "Galileo Sky",
		support_models: "Все",
		protocol_completed: "TCP",
		port: "35100"
	},
	{
		name: "Teltonika",
		support_models: "Все",
		protocol_completed: "TCP",
		port: "35110"
	},
	{
		name: "Neomatica",
		support_models: "Все",
		protocol_completed: "TCP",
		port: "35120"
	},
	{
		name: "Sattelite solution",
		support_models: "Все",
		protocol_completed: "TCP",
		port: "35140"
	}
];
